import {gql} from 'apollo-angular';


export const suscribeToNotification = gql`
  mutation suscribeToNotification($notificationDevice: NotificationDeviceInput) {
    notificationDevice: suscribeToNotification(notificationDevice: $notificationDevice) {
      id
      Token_NotificationDevice
      State_NotificationDevice
    }
  }
`;

export const firebaseAuth = gql`mutation firebaseAuth($user: GoogleAuthInput) {
  firebaseUser: firebaseAuth(user: $user) {
    id
    UID
    Token
    Email
    Name
    Given_Name
    AcceptTerms
    ShowGuide
    Family_Name
    Picture
    Locale
    Verified_Email
    Refresh_Token
    Created_At
    Updated_At
  }
}`;

export const sendMessage = gql`
mutation sendMessage($message: String!) {
  result: sendMessage(message: $message) {
    id
    Admin {
      id
      Name
      Given_Name
      Picture
    }
    GoogleAuth {
      id
      Name
      Given_Name
      Picture
      Messages {
        id
        File {
          id
          FileType {
            id
          }
        }
        Admin {
          id
          Email
          Name
          Given_Name
          Family_Name
          Picture
        }
        Message
        Created_At
      }
    }
    UserAnonymous {
      id
      Name
      Email
      Messages {
        id
        File {
          id
          FileType {
            id
          }
        }
        Admin {
          id
          Email
          Name
          Given_Name
          Family_Name
          Picture
        }
        Message
        Created_At
      }
    }
    File {
      id
    }
    Message
    Created_At
    Updated_At
  }
}`;

export const marckUseful = gql`
  mutation marckUseful($id: ID!) {
    Respond: marckUseful(id: $id) {
      id
      Respond
      Created_At
      Updated_At
      Useful
      Useless
      Admin {
        id
        Name
        Picture
      }
    }
}`;

export const marckUseless = gql`
  mutation marckUseless($id: ID!) {
    Respond : marckUseless(id: $id) {
      id
      Respond
      Created_At
      Updated_At
      Useful
      Useless
      Admin {
        id
        Name
        Picture
      }
    }
}`;

export const suscribeToChatNotifications = gql`
  mutation suscribeToChatNotifications(
    $notificationDevice: NotificationDeviceInput
  ) {
    notificationDevice: suscribeToChatNotifications(
      notificationDevice: $notificationDevice
    ) {
      id
      Token_NotificationDevice
      State_NotificationDevice
    }
  }
`;



