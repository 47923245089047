import { FcmService } from "../services/fcm.service";
import { AngularFireAuth } from "@angular/fire/auth";
import { State, StateContext, Selector, Action, Store } from "@ngxs/store";
import { User } from "../interface/user";
import { ApiService } from "../services/api.service";
import { tap, catchError } from "rxjs/operators";
import { MatSnackBar } from "@angular/material/snack-bar";
const CryptoJS = require("crypto-js");
import { Socket } from "ngx-socket-io";
import { AngularFirePerformance, trace } from "@angular/fire/performance";
import { of } from "rxjs";
const cloneDeep = require('clone-deep');
import {
  SendMessage,
  AddMessage,
  SelectUser,
  PlaceID,
  UpdateUserSession,
  WatchIdToken,
  UserByUID,
  FirebaseAuth,
  SusbcribeToFCM,
  SuscribeToChatNotifications,
  getPlacesWithMessagesByUser,
} from "./actions/user.actions";
import { environment } from "src/environments/environment.prod";
import { Injectable } from "@angular/core";
@State<User>({
  name: "user",
  defaults: {
    UserSelected: null,
  },
})
@Injectable()
export class UserState {
  constructor(
    private api: ApiService,
    public afAuth: AngularFireAuth,
    private fcm: FcmService,
    private store: Store,
    private socket: Socket,
    private snackBar: MatSnackBar,
  ) {}

  private lastChatSocket = null;
  private lastMessageSocket = null;

  @Selector()
  static firebaseUser({ firebaseUser }: User) {
    return firebaseUser;
  }

  @Selector()
  static devideAdded({ devideAdded }: User) {
    return devideAdded;
  }

  @Selector()
  static token({ token }: User) {
    return token;
  }

  @Selector()
  static meUser({ firebaseUser: { TavuelUser } }: User) {
    return TavuelUser;
  }

  @Selector()
  static UID({ firebaseUser: { UID } }: User) {
    return UID;
  }

  @Selector()
  static placeId({ placeId }: User) {
    return placeId;
  }

  @Selector()
  static suscribed({ suscribed }: User) {
    return suscribed;
  }

  @Selector()
  static UserSelected({ UserSelected }: User) {
    return UserSelected;
  }

  @Selector()
  static Places({ Places }: User) {
    return Places;
  }

  @Selector()
  static NotificationDevices({ NotificationDevices }: User) {
    return NotificationDevices;
  }

  ngxsOnInit({ dispatch }: StateContext<User>) {
    this.afAuth.useDeviceLanguage();
    this.fcm.receiveMessages();
    dispatch(new WatchIdToken());
  }

  openSnackBar = (message: string, button: string, duration: number) => {
    this.snackBar.open(message, button, {
      duration,
    });
  };

  @Action(SendMessage)
  sendMessage(
    { getState, patchState }: StateContext<User>,
    { message }: SendMessage
  ) {
    const place = getState().placeId;
    return this.api.sendMessage(message).pipe(
      tap(({ data: { result } }) => {
        const { suscribed } = getState();
        if (result.UserAnonymous && result.UserAnonymous.id && !suscribed) {
          patchState({
            UserSelected: {
              UserAnonymous: result.UserAnonymous,
              UserGoogleAuth: null,
            },
            suscribed: true,
          });
          if(this.lastMessageSocket){
            this.lastMessageSocket.unsubscribe();
          }
          this.lastMessageSocket = this.socket
            .fromEvent(`new-message-anonymous-${result.UserAnonymous.id}-${place}`)
            .subscribe((res: string) => {
              const bytes1 = CryptoJS.AES.decrypt(res, environment.chatKey);
              const newMessage = JSON.parse(bytes1.toString(CryptoJS.enc.Utf8));
              const userSelected = getState().UserSelected;
              patchState({
                UserSelected: {
                  UserAnonymous: newMessage.UserAnonymous && {
                    ...userSelected.UserAnonymous,
                    Messages: [
                      newMessage.Message,
                      ...userSelected.UserAnonymous.Messages,
                    ],
                  },
                  UserGoogleAuth: null,
                },
              });
            });
        }
        if (result.TavuelUser && result.UserGoogleAuth.id && !suscribed) {
          patchState({
            UserSelected: {
              UserAnonymous: null,
              UserGoogleAuth: result.UserGoogleAuth,
            },
            suscribed: true,
          });
        }
      })
    );
  }

  @Action(AddMessage)
  addMessage({}: StateContext<User>, { message }: AddMessage) {
    // return this.api.addMessage(message).pipe(
    //   tap(({ data: { send } }) => {
    //     console.log('que p[asaaa aqui');
    //   }),
    // );
  }

  @Action(WatchIdToken)
  watchIdToken({ dispatch }: StateContext<User>) {
    this.afAuth.useDeviceLanguage();
    return this.afAuth.idTokenResult.pipe(
      tap((idToken) => {
        if (idToken) {
          const {
            claims: { user_id },
            token,
            signInProvider,
          } = idToken;
          if (signInProvider === "anonymous") {
            const info = {
              token,
              isGhost: "1",
              ShowGuide: true,
              firebaseUser: { UID: user_id },
            };
            dispatch(new UpdateUserSession(info));
          }
        } else {
          const info = {
            token: null,
            isGhost: "1",
            ShowGuide: true,
            firebaseUser: null,
          };
          dispatch(new UpdateUserSession(info));
        }
      }),
      trace("WatchIdToken")
    );
  }

  @Action(UserByUID)
  userByUID(
    { dispatch, patchState, getState }: StateContext<User>,
    { token, uid, needPlaces, socket }: UserByUID
  ) {
    return this.api.userByUID(token, uid).pipe(
      tap(({ data: { firebaseUser } }) => {
        if (firebaseUser) {
          dispatch(new SelectUser(firebaseUser, null));
          if(needPlaces){
            dispatch(new getPlacesWithMessagesByUser(firebaseUser.id));
          }
          if(socket){
            if(this.lastChatSocket){
              this.lastChatSocket.unsubscribe();
            }
            this.lastChatSocket = this.socket
            .fromEvent(`new-message-user-${firebaseUser.id}-${getState().placeId}`)
            .subscribe((res: string) => {
              const bytes1 = CryptoJS.AES.decrypt(res, environment.chatKey);
              const newMessage = JSON.parse(bytes1.toString(CryptoJS.enc.Utf8));
              const userSelected = getState().UserSelected;
              patchState({
                UserSelected: {
                  UserAnonymous: null,
                  UserGoogleAuth: newMessage.UserGoogleAuth && {
                    ...userSelected.UserGoogleAuth,
                    Messages: [
                      newMessage.Message,
                      ...userSelected.UserGoogleAuth.Messages,
                    ],
                  },
                },
              });
            });
          }
        }
      }),
      catchError((err) => {
        // const [{ message }] = graphQLErrors;
        this.snackBar.open(err, "🙄", {
          duration: 3000,
          panelClass: ["custom-snackbar-error"],
        });
        return of(null);
      })
    );
  }

  @Action(FirebaseAuth)
  firebaseAuth({}: StateContext<User>, { user, ghostUID }: FirebaseAuth) {
    const {
      additionalUserInfo: { providerId, profile },
      user: { uid, email, emailVerified, phoneNumber, displayName, photoURL },
    } = user;
    let signInUser: any = {
      Email: email,
      Picture: photoURL,
      Name: displayName,
      Verified_Email: emailVerified,
      Phone_Number: phoneNumber,
      UID: uid,
      Provider_Id: providerId,
      GhostUID: ghostUID,
      AcceptTerms: true,
    };
    if (profile) {
      const { family_name, given_name, locale } = profile;
      signInUser = {
        ...signInUser,
        Locale: locale || navigator.language,
        Given_Name: given_name,
        Family_Name: family_name,
      };
    } else {
      signInUser = {
        ...signInUser,
        Locale: navigator.language,
      };
    }
    return this.api.firebaseAuth(signInUser).pipe(
      catchError((eerr) => {
        this.snackBar.open("Error al autenticar al usuario", "OK", {
          duration: 3000,
          panelClass: ["custom-snackbar-error"],
        });
        return of(null);
      })
    );
  }

  @Action(SusbcribeToFCM)
  susbcribeToFCM(
    { dispatch, getState, patchState }: StateContext<User>,
    { firstInt }: SusbcribeToFCM
  ) {
    const userSelected = getState().UserSelected;
    if (userSelected) {
      return this.fcm.requestPermission().pipe(
        tap((fcmToken) => {
          const { UserSelected: { UserGoogleAuth } } = getState();
          let notification = {State_NotificationDevice: false};
          if (UserGoogleAuth && UserGoogleAuth.TavuelUser && UserGoogleAuth.TavuelUser.NotificationDevices) {
            notification = UserGoogleAuth.TavuelUser.NotificationDevices.find(
              (device) => device.Token_NotificationDevice === fcmToken
            );
          }
          if (notification && firstInt && UserGoogleAuth) {
            patchState({
              devideAdded: notification.State_NotificationDevice,
            });
          } else {
            dispatch(new SuscribeToChatNotifications(fcmToken));
          }
        }),
        trace("SusbcribeToFCM"),
        catchError((err) => {
          this.snackBar.open(err, "OK", {
            duration: 3000,
            panelClass: ["custom-snackbar-error"],
          });
          return of(null);
        })
      );
    } else {
      return null;
    }
  }

  @Action(SuscribeToChatNotifications)
  SuscribeToChatNotifications(
    { patchState, getState }: StateContext<User>,
    { fcmToken }: SuscribeToChatNotifications
  ) {
    const { UserSelected: { UserAnonymous, UserGoogleAuth} } = getState();
    let notification = null;
    let info = {
      id: null,
      FK_User: null,
      FK_UserAnonymous: null,
      State_NotificationDevice: true,
      Token_NotificationDevice: fcmToken,
    };
    if (UserGoogleAuth && UserGoogleAuth.TavuelUser) {
      notification = UserGoogleAuth.TavuelUser.NotificationDevices && UserGoogleAuth.TavuelUser.NotificationDevices.find(
        device => device.Token_NotificationDevice === fcmToken
        );
      info.FK_User = UserGoogleAuth.TavuelUser.id;
    }
    if (UserAnonymous && UserAnonymous) {
      info.FK_UserAnonymous = UserAnonymous.id;
    }
    info.id = notification && notification.id,
    info.State_NotificationDevice = notification ? !!notification.State_NotificationDevice : true;

    return this.api.suscribeToChatNotifications(info).pipe(
      tap(({ data: { notificationDevice } }) => {
        if (notificationDevice) {
          const clonedMe = cloneDeep(UserGoogleAuth);
          const clonedMeAnonymous = cloneDeep(UserAnonymous);
          if (clonedMe) {
            if (info.id) {
              clonedMe.TavuelUser.NotificationDevices = clonedMe.TavuelUser.NotificationDevices.map(
                device =>
                  device.id === notificationDevice.id
                    ? notificationDevice
                    : device
              );
            } else {
              clonedMe.TavuelUser.NotificationDevices.push(notificationDevice);
            }
          }
          patchState({
            UserSelected: {
              UserGoogleAuth: clonedMe,
              UserAnonymous: clonedMeAnonymous,
            },
            NotificationDevices: [notificationDevice],
            devideAdded: notificationDevice
              ? info.State_NotificationDevice
              : true
          });
        }
      }),
      trace('SuscribeToChatNotifications'),
    );
  }

  @Action(SelectUser)
  selectUser(
    { patchState, getState }: StateContext<User>,
    { UserAnonymous, UserGoogleAuth }: SelectUser
  ) {
    const userId =
      UserGoogleAuth &&
      CryptoJS.AES.encrypt(
        JSON.stringify(UserGoogleAuth.id),
        environment.chatKey
      ).toString();
    const anonymousId =
      UserAnonymous &&
      CryptoJS.AES.encrypt(
        JSON.stringify(UserAnonymous.id),
        environment.chatKey
      ).toString();
    const placeId = CryptoJS.AES.encrypt(getState().placeId, environment.chatKey).toString();
    return this.api.getMessages(userId, anonymousId, placeId).pipe(
      tap(({ data: { Messages } }) => {
        patchState({
          UserSelected: {
            UserAnonymous,
            UserGoogleAuth: {
              ...UserGoogleAuth,
              Messages,
            },
          },
        });
      }),
      trace("SelectUser")
    );
  }

  @Action(PlaceID)
  PlaceId(
    { patchState }: StateContext<User>,
    { placeId }: PlaceID
  ) {
    return patchState({ placeId });
  }

  @Action(getPlacesWithMessagesByUser)
  getPlacesWithMessagesByUser(
    { patchState, getState }: StateContext<User>,
    { userID }: getPlacesWithMessagesByUser
  ) {
      return this.api.getPlacesWithMessagesByUser(userID).pipe(
        tap(({ data:{ Places } }) => {
          patchState({ Places: Places });
        }),
        trace('getPlacesWithMessagesByUser'),
      );
  }
}
