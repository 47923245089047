import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'coinFormat'
})
export class CoinFormatPipe implements PipeTransform {

  transform(value: any): any {
    return value.toLocaleString(undefined, { maximumFractionDigits: 2 });
  }

}
