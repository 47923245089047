import { Pipe, PipeTransform } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Pipe({
  name: 'orderDetailByType'
})
export class OrderDetailByTypePipe implements PipeTransform {

  constructor(private db: AngularFirestore) {}

  transform(value: any): any {
    return this.db.collection('orderDetail', detail => detail.where('fkProductTypeInstitutionType', '==', value.id)).valueChanges();
  }

}
