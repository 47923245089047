import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { MatSnackBar } from '@angular/material/snack-bar';
@Injectable({
  providedIn: 'root'
})
export class FcmService {

  constructor(
    private afMessaging: AngularFireMessaging,
    private snack: MatSnackBar) {
  }

  requestPermission() {
    return this.afMessaging.requestToken;
  }

  receiveMessages = () => {
    this.afMessaging.messages.subscribe((message: any) => {
      if (message.notification) {
        const { body, title, click_action, icon, tag } = message.notification;
        this.snack.open(`${title}: ${body}`, 'Ok', { duration: 3000 });
        Notification.requestPermission().then((result) => {
          if (result === 'denied') {
            return;
          }
          if (result === 'default') {
            return;
          }
          navigator.serviceWorker.ready
            .then((registration) => {
              registration.showNotification(title, {
                body,
                tag,
                icon,
                actions: [{
                  action: click_action,
                  icon,
                  title: 'ir'
                }
                ]
              });
            })
            .catch(er => {
              console.log(er);
            });
        });
      }
    });
  }

}
