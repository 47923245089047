import { State, StateContext, Selector, Action } from '@ngxs/store';
import { Questions } from '../interface/question';
import { ApiService } from '../services/api.service';
import { trace } from '@angular/fire/performance';
import { tap } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  GetQuestionTypes,
  SelectQuestionType,
  SelectQuestion,
  SelectRespond, SetUseful, SetUseless } from './actions/question.actions';
import { Injectable } from '@angular/core';
@State<Questions>({
  name: 'Questions',
  defaults: {
    alreadyResponded: [],
  }
})
@Injectable()
export class QuestionState {
  constructor(
    private api: ApiService,
    public afAuth: AngularFireAuth,
    private snackBar: MatSnackBar,
  ) { }

  @Selector()
  static questionTypes({ questionTypes }: Questions) {
    return questionTypes;
  }

  @Selector()
  static questionSelected({ questionSelected }: Questions) {
    return questionSelected;
  }

  @Selector()
  static questionTypeSelected({ questionTypeSelected }: Questions) {
    return questionTypeSelected;
  }

  @Selector()
  static alreadyResponded({ alreadyResponded }: Questions) {
    return alreadyResponded;
  }

  @Selector()
  static respondSelected({ respondSelected }: Questions) {
    return respondSelected;
  }

  ngxsOnInit({ }: StateContext<Questions>) {
  }

  openSnackBar = (
    message: string,
    button: string,
    duration: number,
  ) => {
    this.snackBar.open(message, button, {
      duration,
    });
  }

  @Action(SelectRespond)
  selectRespond(
    { patchState }: StateContext<Questions>,
    { respondSelected }: SelectRespond
  ) {
    patchState({ respondSelected });
  }

  @Action(SelectQuestion)
  selectQuestion(
    { patchState }: StateContext<Questions>,
    { questionSelected }: SelectQuestion
  ) {
    patchState({ questionSelected });
  }

  @Action(SelectQuestionType)
  selectQuestionType(
    { patchState }: StateContext<Questions>,
    { questionTypeSelected }: SelectQuestionType
  ) {
    patchState({ questionTypeSelected });
  }

  @Action(GetQuestionTypes)
  getQuestionTypes(
    { patchState, getState }: StateContext<Questions>,
    { placeId }: GetQuestionTypes
  ) {
    const questions = getState().questionTypes;
    return this.api.questionTypesByPlace(placeId).pipe(
      tap(({ data: { questionTypes } }) => {
        patchState({questionTypes});
      }),
      trace('GetQuestionTypes'),
    );
  }

  @Action(SetUseful)
  setUseful(
    { patchState, getState }: StateContext<Questions>,
    { respondId }: SetUseful
  ) {
      return this.api.marckUseful(respondId).pipe(
        tap(({ data: { Respond } }) => {
          if (Respond && Respond.id) {
            const questionSelected = getState().questionSelected;
            const questionTypeSelected = getState().questionTypeSelected;
            const questionTypes = getState().questionTypes;
            const alreadyResponded = getState().alreadyResponded;
            patchState({
              alreadyResponded: [...alreadyResponded, Respond],
              questionSelected: {
                ...questionSelected,
                Respond,
              },
              questionTypeSelected: {
                ...questionTypeSelected,
                Questions: questionTypeSelected.Questions.map(q => q.id === questionSelected.id ? {
                  ...q,
                  Respond,
                } : q)
              },
              questionTypes: questionTypes.map(qt => {
                  if (qt.id === questionTypeSelected.id) {
                    return {
                      ...qt,
                      Questions: qt.Questions.map(q => q.id === questionSelected.id ? {
                        ...q,
                        Respond,
                      } : q),
                    }
                  } else {
                    return qt;
                  }
              })
            });
          }
        }),
        trace('SetUseful'),
      );
  }

  @Action(SetUseless)
  setUseless(
    { patchState, getState }: StateContext<Questions>,
    { respondId }: SetUseless
  ) {
      return this.api.marckUseless(respondId).pipe(
        tap(({ data: { Respond } }) => {
          if (Respond && Respond.id) {
            const questionSelected = getState().questionSelected;
            const questionTypeSelected = getState().questionTypeSelected;
            const questionTypes = getState().questionTypes;
            const alreadyResponded = getState().alreadyResponded;
            patchState({
              alreadyResponded: [...alreadyResponded, Respond],
              questionSelected: {
                ...questionSelected,
                Respond,
              },
              questionTypeSelected: {
                ...questionTypeSelected,
                Questions: questionTypeSelected.Questions.map(q => q.id === questionSelected.id ? {
                  ...q,
                  Respond,
                } : q)
              },
              questionTypes: questionTypes.map(qt => {
                  if (qt.id === questionTypeSelected.id) {
                    return {
                      ...qt,
                      Questions: qt.Questions.map(q => q.id === questionSelected.id ? {
                        ...q,
                        Respond,
                      } : q),
                    }
                  } else {
                    return qt;
                  }
              })
            });
          }
        }),
        trace('SetUseless'),
      );
  }
}
