import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
const CryptoJS = require('crypto-js');

@Pipe({
  name: 'message'
})
export class MessagePipe implements PipeTransform {

  transform(message: string): string {
    const bytes  = CryptoJS.AES.decrypt(message, environment.chatKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  }

}
