import {gql} from 'apollo-angular';



// export const validatePhoneNumber = gql`
//   query validatePhoneNumber($phoneNumber: String!) {
//     invalid: validatePhoneNumber(phoneNumber: $phoneNumber)
//   }`;

// export const getUserByToken = gql`
//   query getUserByToken($token: String!) {
//     user: getUserByToken(token: $token) {

//     }
//   }`;

export const questionTypesByPlace = gql`
  query questionTypesByPlace($placeId: String!) {
    questionTypes : questionTypesByPlace(placeId: $placeId) {
      id
      Type
      Questions {
        id
        Question
        Created_At
        Updated_At
        Admin {
          id
          Name
          Picture
        }
        Respond {
          id
          Respond
          Created_At
          Updated_At
          Useful
          Useless
          Admin {
            id
            Name
            Picture
          }
        }
      }
      Created_At
      Updated_At
    }
}`;



export const userByUID = gql`
  query userByUID($uid: ID) {
    firebaseUser: userByUID(uid: $uid) {
      id
      Name
      Picture
      Email
      TavuelUser {
        id
        NotificationDevices {
          id
          Token_NotificationDevice
          State_NotificationDevice
        }
      }
    }
  }`;

export const getMessages = gql`
  query getMessages($userId: String, $anonymousId: String, $placeId: String!) {
    Messages : getMessages(userId: $userId, anonymousId: $anonymousId, placeId: $placeId) {
      id
      File {
        id
        FileType {
          id
        }
      }
      Admin {
        id
        Email
        Name
        Given_Name
        Family_Name
        Picture
      }
      Message
      Created_At
    }
}`;

export const getPlacesWithMessagesByUser = gql`
  query getPlacesWithMessagesByUser($userID: ID) {
    Places : getPlacesWithMessagesByUser(userID: $userID) {
      id
      Name_Place
      Description_Place
      Route_File
    }
}`;

