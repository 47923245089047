import { Injectable } from '@angular/core';
import { Observable, of, interval } from 'rxjs';
declare let google;

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  matrixService = new google.maps.DistanceMatrixService();
  constructor() {}

  getLocation(): Observable<any> {
    return new Observable<any>(observer => {
      if (window.navigator && window.navigator.geolocation) {
        window.navigator.geolocation.getCurrentPosition(
          position => {
            observer.next(position);
            observer.complete();
          },
          error => observer.error(error)
        );
      } else {
        observer.error('Unsupported Browser');
      }
    });
  }

  watchCurrentPosition() {
    interval(1000).subscribe(x => {
      return this.getLocation().subscribe(res => {
        console.log(res);
      });
    });
  }

  getBestPoint = (start, destinations) => {
    try {
      return new Observable(observer => {
        this.matrixService.getDistanceMatrix(
          { origins: [start], destinations, travelMode: 'DRIVING' },
          (results: any) => {
            observer.next(results);
          }
        );
      });
    } catch (error) {
      return of(null);
    }
  }

  getDistance = (origin, points) => {
    const results = [];
    return new Observable(observer => {
      const R = 6371; // Radius of the earth in km
      points.forEach(_p => {
        const dLat = this.deg2rad(_p.lat - origin.lat); // deg2rad below
        const dLon = this.deg2rad(_p.lng - origin.lng);
        const a =
          Math.sin(dLat / 2) * Math.sin(dLat / 2) +
          Math.cos(this.deg2rad(origin.lat)) *
            Math.cos(this.deg2rad(_p.lat)) *
            Math.sin(dLon / 2) *
            Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        results.push({
          id: _p.id ? _p.id : null,
          distance: R * c
        });
      });
      observer.next(results);
    });
  }

  private deg2rad = deg => {
    return deg * (Math.PI / 180);
  }
}
