import {Apollo} from 'apollo-angular';
import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import {
  // getUserByToken
  getMessages,userByUID, questionTypesByPlace, getPlacesWithMessagesByUser
} from './graphql/querys';
import {
  marckUseless, firebaseAuth, sendMessage, suscribeToChatNotifications,
  marckUseful
} from './graphql/mutations';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private apollo: Apollo,
    private http: HttpClient
  ) { }

  context = (token) => {
    if (token) {
      return {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
    }
    return {};
  }

  ///////////////////////////////////////
  /////////////QUERIES//////////////////
  //////////////////////////////////////

  // validatePhoneNumber = (token, phoneNumber) =>
  //   this.apollo.query<any>({
  //     query: validatePhoneNumber,
  //     variables: { phoneNumber },
  //     context: this.context(token)
  //   })

  // getUserByToken = (token) =>
  //   this.apollo.query<any>({
  //     query: getUserByToken,
  //     variables: { token },
  //     context: this.context(token)
  //   })

  getMessages = (userId, anonymousId, placeId) =>
    this.apollo
      .use('chat')
      .query<any>({
        query: getMessages,
        variables: { userId, anonymousId, placeId },
    })

  getPlacesWithMessagesByUser = (userID) =>
    this.apollo
      .use('chat')
      .query<any>({
        query: getPlacesWithMessagesByUser,
        variables: { userID },
    })

  questionTypesByPlace = (placeId) =>
    this.apollo
      .use('chat')
      .query<any>({
        query: questionTypesByPlace,
        variables: { placeId },
    })

  ///////////////////////////////////////
  /////////////MUTATION//////////////////
  //////////////////////////////////////

  sendMessage = (message) =>
    this.apollo
      .use('chat')
      .mutate<any>({
        mutation: sendMessage,
        variables: { message },
    })

  suscribeToChatNotifications = (notificationDevice) =>
    this.apollo
    .use('chat')
    .mutate<any>({
      mutation: suscribeToChatNotifications,
      variables: { notificationDevice }
    })

  marckUseless = (id) =>
    this.apollo
    .use('chat')
    .mutate<any>({
      mutation: marckUseless,
      variables: { id }
    })

  marckUseful = (id) =>
    this.apollo
    .use('chat')
    .mutate<any>({
      mutation: marckUseful,
      variables: { id }
    })

  firebaseAuth = (user) =>
    this.apollo.mutate<any>({
      mutation: firebaseAuth,
      variables: { user }
    })

  userByUID = (token, uid) =>
    this.apollo.mutate<any>({
      mutation: userByUID,
      context: this.context(token),
      variables: { uid }
    })
}
