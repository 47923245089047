import { Observable } from 'rxjs';
import { UserAnonymous, FirebaseUser } from 'src/app/interface/user';

export class FirebaseAuth {
  static readonly type = '[User] FirebaseAuth]';
  constructor(public readonly user: any, public readonly ghostUID?: any) { }
}

export class SendMessage {
  static readonly type = '[User] SendMessage]';
  constructor(public readonly message: string) { }
}

export class AddMessage {
  static readonly type = '[User] AddMessage]';
  constructor(public readonly message: string) { }
}

export class SelectUser {
  static readonly type = '[User] SelectUser]';
  constructor(public readonly UserGoogleAuth?: UserAnonymous, public readonly UserAnonymous?: FirebaseUser) { }
}

export class WatchIdToken {
  static readonly type = '[User] WatchIdToken]';
}

export class UpdateUserSession {
  static readonly type = '[User] UpdateUserSession]';
  constructor(public readonly info: {
    firebaseUser: FirebaseUser,
    token: string,
    isGhost: string,
    ShowGuide?: boolean,
  }) { }
}

export class UserByUID {
  static readonly type = '[User] UserByUID]';
  constructor(public readonly token: string, public readonly uid: string, public readonly needPlaces?: boolean, public readonly socket?: boolean) { }
}

export class PlaceID {
  static readonly type = '[User] PlaceID]';
  constructor(public readonly placeId: number) { }
}

export class getPlacesWithMessagesByUser {
  static readonly type = '[User] getPlacesWithMessagesByUser';
  constructor(public readonly userID: number) { }
}

export class SusbcribeToFCM {
  static readonly type = '[FCM] SusbcribeToFCM]';
  constructor(public readonly firstInt?: boolean) { }
}

export class SuscribeToChatNotifications {
  static readonly type = '[FCM] SuscribeToChatNotifications]';
  constructor(public readonly fcmToken: string) { }
}
