import { QuestionType, Question, Respond, QuestionTypeInput, QuestionInput, RespondInput } from 'src/app/interface/question';

export class GetQuestionTypes {
  static readonly type = '[Question] Query GetQuestionTypes]';
  constructor(
    public readonly placeId: string,
  ) { }
}

export class DeleteQuestionType {
  static readonly type = '[Question] Mutation DeleteQuestionType]';
  constructor(
    public readonly id: string,
) { }
}

export class EditQuestionType {
    static readonly type = '[Question] Mutation EditQuestionType]';
    constructor(
      public readonly id: number,
      public readonly data: QuestionTypeInput,
  ) { }
}

export class CreateQuestionType {
    static readonly type = '[Question] Mutation CreateQuestionType]';
    constructor(
      public readonly data: QuestionTypeInput,
  ) { }
  }

export class SelectQuestionType {
    static readonly type = '[Question] Action SelectQuestionType]';
    constructor(
      public readonly questionTypeSelected: QuestionType,
  ) { }
  }

export class DeleteQuestion {
    static readonly type = '[Question] Mutation DeleteQuestion]';
    constructor(
      public readonly id: string,
  ) { }
  }

export class EditQuestion {
      static readonly type = '[Question] Mutation EditQuestion]';
      constructor(
        public readonly id: number,
        public readonly data: QuestionInput,
    ) { }
  }

export class CreateQuestion {
      static readonly type = '[Question] Mutation CreateQuestion]';
      constructor(
        public readonly data: QuestionInput,
    ) { }
    }

export class SelectQuestion {
      static readonly type = '[Question] Action SelectQuestion]';
      constructor(
        public readonly questionSelected: Question,
    ) { }
    }

export class DeleteRespond {
    static readonly type = '[Question] Mutation DeleteRespond]';
    constructor(
      public readonly id: string,
  ) { }
  }

export class EditRespond {
      static readonly type = '[Question] Mutation EditRespond]';
      constructor(
        public readonly id: number,
        public readonly data: RespondInput,
    ) { }
  }

export class CreateRespond {
    static readonly type = '[Question] Mutation CreateRespond]';
    constructor(
      public readonly data: RespondInput,
  ) { }
  }

export class SelectRespond {
    static readonly type = '[Question] Action SelectRespond]';
    constructor(
      public readonly respondSelected: Respond,
  ) { }
}

export class SetUseful {
  static readonly type = '[Question] Action SetUseful]';
  constructor(
    public readonly respondId: string,
) { }
}


export class SetUseless {
  static readonly type = '[Question] Action setUseless]';
  constructor(
    public readonly respondId: string,
) { }
}



