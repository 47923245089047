import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateFormatFrom'
})
export class DateFormatFromPipe implements PipeTransform {

  transform(date): any {
    return `${moment(date).startOf('minutes').locale('es-US').fromNow()}`;
  }

}
