import { Pipe, PipeTransform } from '@angular/core';
import { File } from '../../interface/user';
import { DomSanitizer } from '@angular/platform-browser';


@Pipe({
  name: 'img'
})
export class ImgPipe implements PipeTransform {
  private defaultImg = 'https://firebasestorage.googleapis.com/v0/b/tavuel506.appspot.com/o/utilities%2FemptyImg.png?alt=media&token=d3b629c3-3506-4bc7-aef6-21f7ac63b1e8.png';
  constructor(
    private sanitizer?: DomSanitizer,
  ) {}
  transform(files: File[], type: string) {
    if (files) {
      const file = files.find(_f => _f.FileType.id === type);
      return file ? this.sanitizer.bypassSecurityTrustUrl(file.Route_File) : this.defaultImg;
    } else {
      return this.defaultImg;
    }
  }
}
