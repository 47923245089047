// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  api:  'https://prod.tavuel.com/api', // 'https://api.tavuel.com/nodejs', // 'http://localhost:5000',
  p12Upload: 'http://localhost/api.php',
  cryptoAES: 'xyXTvZQTcQRt5AN',
  apiPlaces: 'http://localhost:5050',
  chatKey: '89sdJKMm_asdjljjK299JASD_AJSD1233nnn',
  apiChat:  'https://prod.tavuel.com/chat', //'http://localhost:5080/chat', // 'http://api.tavuel.com/message/chat', //
  socketChat:  'https://prod.tavuel.com', //'http://localhost:5080', // 'http://api.tavuel.com/message', //
  chatClientKey: '906LczO-asZAAAA336_1AMasdWSAa__JeaCBZ4443tfRAt_Tmj',
  production: false,
// Develop
  firebase : {
    apiKey: 'AIzaSyCBVV-BADea7PB_33t1dzx-h3KIpZsdwk0',
    authDomain: 'tavuel506.firebaseapp.com',
    databaseURL: 'https://tavuel506.firebaseio.com',
    projectId: 'tavuel506',
    storageBucket: 'tavuel506.appspot.com',
    messagingSenderId: '536950237048',
    appId: '1:536950237048:web:3c9094800aa0bab8'
  },
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
