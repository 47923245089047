import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'details'
})
export class DetailsPipe implements PipeTransform {

  transform(details: any): any {
    let newDetail = '';
    details.forEach((detail, i) => {
      if (detail.Key_ProductKeyFeature !== 'Color' && detail.Key_ProductKeyFeature !== 'Otros') {
        newDetail += `${ detail.Value_ProductFeatureDetail } ${ detail.Key_ProductKeyFeature }`;
      } else {
        newDetail += detail.Value_ProductFeatureDetail;
      }
      if (i < details.length - 1) {
        newDetail += ', ';
      }
    });
    return newDetail;
  }

}
