import { CanActivate } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Injectable } from "@angular/core";

@Injectable()
export class AdminGuard implements CanActivate {
  constructor(
  ) { }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return of(true);
  }

}
